import {Link} from 'react-router-dom'

import Logo from '../assets/logo.png'

import './Menu.css'

export function Menu(){ 
    return (
    <nav className="menu">
        <div className="content">

            <Link className="full-center" to="/"><img src={Logo} className="logo" alt="" /></Link>
            
            <div>
                
                <Link className='button' to="/">HOME</Link>
                <Link className='button' to="/portifolio">PORTFÓLIO</Link>
                <Link className='button' to="/contato">CONTATO</Link>
            </div>

        </div>
    </nav>
)}