import {BrowserRouter, Route, Routes} from "react-router-dom"
import {Home} from "./pages/home"
import {Portifolio} from "./pages/portifolio"
import {Contato} from "./pages/contato"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home/>}></Route>
        <Route path="/portifolio" exact element={<Portifolio/>}></Route>
        <Route path="/contato" exact element={<Contato/>}></Route>
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
