import {Link} from 'react-router-dom'

import IsoLogo from '../assets/logo-iso.png'

import './Footer.css'

export function Footer(){
    return (
    <footer>
        <div className="footer">

            <div className="nav">
                <Link className="logo-footer" to="/"><img src={IsoLogo} alt="" /></Link>

                <div className='button-container'>
                    <Link className='button' to="/">HOME</Link>
                    <Link className='button' to="/portifolio">PORTFÓLIO</Link>
                    <Link className='button' to="/contato">CONTATO</Link>
                </div>
            </div>

            <div className="social">
                <a href='https://www.instagram.com/dr.cortes' target={"blank"}>Instagram</a>
                <a href='https://github.com/DrTCortes' target={"blank"}>Github</a>
                <a href='https://www.behance.net/thiagocortes' target={"blank"}>Behance</a>
                <a href='https://www.linkedin.com/in/thiago-cortes' target={"blank"}>Linkedin</a>
            </div>
        </div>
    </footer>
)}