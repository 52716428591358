import React from 'react';

import '../css/global.css'
import './contato.css'

import {Menu} from '../components/Menu'
import {Footer} from '../components/Footer'

export function Contato(){
    return(
        <div className="container">
            <Menu/>
            <Footer/>
        </div>
    )
}