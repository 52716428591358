import {Link} from 'react-router-dom'

import "../css/global.css"
import "./home.css"

import HeroImage from '../assets/image-homepage-profile.png'
import WebDesign from '../assets/web-design.png'
import ComunicacaoVisual from '../assets/comunicacao-visual.png'
import DesignGrafico from '../assets/design-grafico.png'
import Dev from '../assets/dev.png'

import {Menu} from "../components/Menu"
import {Footer} from "../components/Footer"

export function Home(){
    return(   
    <div className="container">
        <Menu/>
        
        <div className="flex-column home">

            <header>
                <div className="flex-column presentation">
                    
                    <h1 className='flex-column align-center'>
                        Olá, eu me chamo Thiago Côrtes e <br/> tenho a Solução Digital que o <br/> seu negócio precisa!
                    </h1>
                    <a href="#about"> SOBRE MIM </a>

                </div>
            </header>

            <section className='flex-column'>

                <div className="flex-row skills">
                    <div className="flex-column align-center">
                        <div className="icon-box"><img src={WebDesign} alt="" /></div>
                        <h2>Web designer</h2>
                    </div>
                    <div className="flex-column align-center">
                        <div className="icon-box"><img src={Dev} alt="" /></div>
                        <h2>Desenvolvedor<br/>Front-end</h2>
                    </div>
                    <div className="flex-column align-center">
                        <div className="icon-box"><img src={DesignGrafico} alt="" /></div>
                        <h2>Designer Grafico</h2>
                    </div>
                    <div className="flex-column align-center">
                        <div className="icon-box"><img src={ComunicacaoVisual} alt="" /></div>
                        <h2>Comunicação Visual</h2>
                    </div>
                </div>

                <div className="skills-description">
                <p>Sou designer com mais de 4 anos de experiência, desenvolvedor front-end júnior, formado em Análise e Desenvolvimento de Sistemas. Eu foco em escrever HTML acessível, usando práticas modernas de CSS e escrevendo um JavaScript limpo. Moro em Salvador-BA, mas também seria feliz trabalhando remotamente. Eu amaria se você desse uma olhada no meu trabalho.</p>
                </div>
            </section>


            <section>
                <div className="img">
                    <img src={HeroImage} alt=""/>
                </div>

                <div id="about">
                    <h2>Sobre mim</h2>
                    <p>Sou designer com mais de 4 anos de experiência, desenvolvedor front-end júnior, formado em Análise e Desenvolvimento de Sistemas. Eu foco em escrever HTML acessível, usando práticas modernas de CSS e escrevendo um JavaScript limpo. Moro em Salvador-BA, mas também seria feliz trabalhando remotamente. Eu amaria se você desse uma olhada no meu trabalho.</p>
                    <Link className='button' to="/portifolio">IR PARA O PORTFÓLIO</Link>
                </div>
            </section>

            <section>
                <h3>Interessado em fazer projetos comigo?</h3>
                <Link className='button' to="/contato">CONTATO</Link>
            </section>

        </div>

        <Footer/>
    </div>
)}

