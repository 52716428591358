import React from 'react';

import '../css/global.css'
import './portifolio.css'

import {Menu} from '../components/Menu'
import {Footer} from '../components/Footer'
import Calculator from '../components/projects/calculator/main/Calculator'

export function Portifolio(){
    return(
        <div className="container">
            <Menu/>

            <div className="flex-column portifolio">
                <Calculator/>
            </div>

            <Footer/>
        </div>
    )
}